<template>
    <div class="page">
        <el-row class="sub-box">
            <div class="login-box">
                <div class="sub-title">
                    ご利用をいただき、誠にありがとうございます。
                </div>
                <div class="title">Qoo10API連携システム</div>
                <el-row class="form-box">
                    <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent>
                        <el-form-item prop="username">
                            <el-input
                                    v-model="form.username"
                                    maxlength="20"
                                    prefix-icon="el-icon-user"
                                    placeholder="ログインアカウントを入力"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input
                                    v-model="form.password"
                                    type="password"
                                    maxlength="20"
                                    prefix-icon="el-icon-lock"
                                    placeholder="ログインパスワードを入力"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <div class="flex-center">
                                <el-input
                                        class="flex"
                                        v-model="form.code"
                                        prefix-icon="el-icon-key"
                                        placeholder="認証コードを入力"
                                ></el-input>
                                <img class="capcha" :src="capcha" @click="getCapcha"/>
                            </div>
                        </el-form-item>
                        <el-form-item class="save-check">
                            <el-checkbox v-model="isCheck" @change="change"
                            >パスワードを保存する
                            </el-checkbox
                            >
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                    style="width: 100%"
                                    type="primary"
                                    class="block"
                                    @click="login()"
                                    native-type=“submit”
                            >ログイン <i class="el-icon-right"></i
                            ></el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
        </el-row>

        <div class="copyright">
            <span>Copyright © 2022 Qoo10. All rights reserved.</span>
        </div>
    </div>
</template>

<script>
    import {getToken, removeToken, setToken} from "@/libs/auth";

    export default {
        name: "Login",
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                    code: "",
                    uuid: "",
                },
                rules: {
                    username: {
                        required: true,
                        message: "ログインアカウントを入力",
                        trigger: ["blur", "change"],
                    },
                    password: {
                        required: true,
                        message: "ログインパスワードを入力",
                        trigger: ["blur", "change"],
                    },
                    code: {
                        required: true,
                        message: "認証コードを入力",
                        trigger: ["blur", "change"],
                    },
                },
                isCheck: false,
                capcha: "",
            };
        },
        computed: {},
        mounted() {
            let token = getToken();
            if (token) {
                this.$router.push("/home");
            } else {
                this.initCache();
                this.getCapcha();
            }
            ;

        },
        methods: {
            change(val) {
                this.isCheck = val;
            },
            login() {
                this.$refs.form.validate(async (valid) => {
                    if (!valid) {
                        return;
                    }
                    if (this.isCheck) {
                        this.$store
                            .dispatch("Login", this.form)
                            .then(() => {
                                localStorage.setItem("QOO_USER", this.form.username);
                                localStorage.setItem("QOO_PASS", this.form.password);
                                this.$router.push("/home").catch(() => {
                                });
                            })
                            .catch(() => {
                            });
                    } else {
                        this.$store.dispatch("Login", this.form).then(() => {
                            localStorage.setItem("QOO_USER", "");
                            localStorage.setItem("QOO_PASS", "");
                            this.$router.push("/home").catch(() => {
                            });
                        });
                    }

                    this.getCapcha();
                });
            },
            async getCapcha() {
                let {code, img, uuid} = await this.$http.common.capcha();
                if (code == 200) {
                    this.capcha = "data:image/png;base64," + img;
                    this.form.uuid = uuid;
                }
            },
            initCache() {
                let username = localStorage.getItem("QOO_USER");
                let password = localStorage.getItem("QOO_PASS");
                username && (this.form.username = username);
                password && (this.form.password = password);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1300px;
    /*  height: 100vh; */
    background: url(../assets/img/bg.png) no-repeat center fixed !important;

    .sub-box {
      width: 100%;
      display: flex;
      justify-content: center;

      .login-box {
        width: 330px;
        height: 332px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 0px;
        padding: 40px;
        margin-top: 7%;

        .sub-title {
          color: #0878ec;
          font-size: 13px;
          text-align: left;
        }

        .title {
          color: black;
          font-weight: bold;
          font-size: 30px;
          text-align: left;
          margin-top: 10px;
        }
      }

      .form-box {
        margin-top: 30px;

        .el-form {
          .el-input__inner {
            box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
          }

          .el-form-item {
            margin-bottom: 20px;
          }

          .flex-center {
            display: flex;
            justify-content: flex-start;

            .flex {
              width: calc(100% - 110px);
            }
          }

          .save-check {
            text-align: left;
            margin-top: -10px;
          }
        }
      }
    }

    .copyright {
      margin-top: 250px;
      color: white;
    }
  }

  .capcha {
    width: 108px;
    height: 40px;
    margin-left: 10px;
  }

  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: black;
  }
</style>
